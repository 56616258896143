import { UseFormReturn } from "react-hook-form";
import Link from "next/link";

import { Dropdown } from "@chef/components";
import { BillingQuery, useBillingQuery } from "@chef/state-management";
import { padPostalCode } from "@chef/helpers";
import { BRAND_PATHS } from "@chef/constants";

import type { WeekSchedulerFormData } from "./types";
import { intl } from "./WeekSchedulerEditor.Intl";

interface AddressDropdownProps {
  formControls: UseFormReturn<WeekSchedulerFormData>;
  handleModalClose: () => void;
}

const createAddressOptions = (args: {
  addresses: BillingQuery["billing"]["addresses"];
}) => {
  const { addresses } = args;

  if (!addresses || args.addresses.length === 0) {
    return [];
  }

  return addresses.map((a) => ({
    value: a.addressId,
    label: `${a.address}, ${padPostalCode(a.postalCode)} ${a.city}`,
  }));
};

export const AddressDropdown = ({ formControls }: AddressDropdownProps) => {
  const { data: billingQuery } = useBillingQuery();

  const addressId = formControls.getValues("addressId");

  if (!billingQuery || !addressId) {
    return null;
  }

  const addressOptions = createAddressOptions({
    addresses: billingQuery.billing.addresses,
  });

  return (
    <>
      <div className="flex justify-between mb-2 text-sm">
        <div>
          <strong>{intl.DELIVERY_ADDRESS}</strong>
        </div>

        <Link
          href={BRAND_PATHS.ADD_NEW_ADDRESS_PAGE}
          className="underline text-primary z-30"
        >
          <strong> {intl.ADD_NEW_ADDRESS}</strong>
        </Link>
      </div>
      <Dropdown
        options={addressOptions}
        {...formControls.register("addressId")}
        onChange={(value) => formControls.setValue("addressId", value)}
        onBlur={undefined}
      />
    </>
  );
};
